import { makeId } from '~/mixins/global'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { useUserStore } from '~/stores/user'
import { useUserBandStore } from '~/stores/userBand'

import { configToQs } from '~/helpers/configToQs'
import { buildNotificationFromRawCoreData } from '~/helpers/notification-v2/buildFromRaw'
import { notificationIsValid } from '~/helpers/notification-v2/socket/index'

import { provideGetNotificationSocketToken } from '~/api-core/Notifications/GetSocketToken'

import type { Notification } from '~/types/notification-v2'

export type NotificationSocketMessageEventHandler = (
  notification: Notification,
) => void

type HandlerArray = [
  NotificationSocketMessageEventHandler,
  ((err: Error) => any) | undefined,
]

export function useNotificationSocket() {
  const { $pinia } = useNuxtApp()
  const { coreFetch } = useProvideCoreFetch()
  const isLoadingSocket = useState(
    'notification-socket-is-loading',
    () => false,
  )
  const socket = useState<WebSocket | null>('notification-socket', () => null)
  const socketHandlerMap = useState<Record<string, HandlerArray>>(
    'notification-handler-map',
    () => ({}),
  )
  const { $onAction: onUserStoreAction } = useUserStore($pinia)
  const { $onAction: onUserBandStoreAction } = useUserBandStore($pinia)

  const userStoreUnsubscribe = ref<ReturnType<typeof onUserStoreAction> | null>(
    null,
  )
  const userBandStoreUnsubscribe = ref<ReturnType<
    typeof onUserBandStoreAction
  > | null>(null)

  function initializeSocketFromToken(token: string): WebSocket {
    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'

    const host = window.location.host.includes(':3000')
      ? 'localhost'
      : window.location.host
    const url = `${protocol}://${host}/notification/ws/?${configToQs({
      token,
    })}`

    const socket = new WebSocket(url)
    return socket
  }

  async function initializeSocket(): Promise<WebSocket> {
    const getNotificationSocketToken =
      provideGetNotificationSocketToken(coreFetch)
    const token = await getNotificationSocketToken().catch(() => {
      return ''
    })

    return initializeSocketFromToken(token)
  }

  function addEvent(
    handler: NotificationSocketMessageEventHandler,
    onErrorHandler?: (err: Error) => any,
  ): string {
    const handlerUid = makeId()

    socketHandlerMap.value[handlerUid] = [handler, onErrorHandler]
    return handlerUid
  }

  function removeEvent(handlerUid: string) {
    if (socketHandlerMap.value[handlerUid])
      delete socketHandlerMap.value[handlerUid]
  }

  async function reloadSocket() {
    if (!socket.value) return
    if (socket.value.OPEN) {
      socket.value.onclose = function () {}
      socket.value.close(1000, 'programatic reload called')
    }
    socket.value = await initializeSocket()
    bindMessageEventListener(socket.value)
  }

  function bindMessageEventListener(notificationSocket: WebSocket) {
    notificationSocket.addEventListener(
      'message',
      ({ data }: { data: any }) => {
        try {
          const parsedData = JSON.parse(data)
          if (!notificationIsValid(parsedData))
            throw new Error('Malformed notification')

          Object.values(socketHandlerMap.value).forEach(
            ([handler, onErrorHandler]) => {
              try {
                handler(buildNotificationFromRawCoreData(parsedData))
              } catch (err) {
                if (onErrorHandler) onErrorHandler(err as Error)
              }
            },
          )
        } catch (_) {
          // mute error
        }
      },
    )
  }

  function closeSocket() {
    if (!socket.value) return
    socket.value.onclose = function () {}
    socket.value.close(1000, 'programatic close called')
  }

  onMounted(async () => {
    if (isLoadingSocket.value) return
    isLoadingSocket.value = true
    try {
      if (!socket.value) {
        socket.value = await initializeSocket()
        bindMessageEventListener(socket.value)
      }

      if (!userBandStoreUnsubscribe.value) {
        userBandStoreUnsubscribe.value = onUserBandStoreAction(
          ({ name, after }) => {
            if (name !== 'PATCH') return
            after(reloadSocket)
          },
        )
      }
      if (!userStoreUnsubscribe.value) {
        userStoreUnsubscribe.value = onUserStoreAction(({ name, after }) => {
          if (name !== 'LOGOUT') return
          after(closeSocket)
        })
      }
    } catch (_) {
      // mute error
    } finally {
      isLoadingSocket.value = false
    }
  })

  return {
    addSocketEvent: addEvent,
    removeSocketEvent: removeEvent,
    reloadSocket,
    closeSocket,
  } as const
}
